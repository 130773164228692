import { APIVersionConfig } from "src/api-versions/api-version-config-dev";
/**
 * @file environment.dev.ts
 */
export const environment = {
  envName: 'dev',
  production: false,
  b2cForgotPassFlow:
    'https://myportaldev.b2clogin.com/myportaldev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_PasswordReset&client_id=37e06d71-49dd-4b1f-912f-0c6591670674&nonce=defaultNonce&redirect_uri=' +
    window.location.origin +
    '&scope=openid&response_type=id_token&prompt=login',
  baseUrl: 'https://myportalapi-dev.empirxhealth.com/api',
  apiGatewayBaseURL: 'https://eh-mp-api-east.azure-api.net/api/bap',
  b2cSignInSignUpFlow:
    'https://myportaldev.b2clogin.com/myportaldev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignUpSignIn&client_id=37e06d71-49dd-4b1f-912f-0c6591670674&nonce=defaultNonce&redirect_uri=' +
    window.location.origin +
    '&scope=openid&response_type=id_token&prompt=login',
  bloblink:
    'https://ehcacustomuidev.blob.core.windows.net/termsofuse/Empirx%20Health%20Reporting%20Portal%20-%20Terms%20Of%20Use.pdf',
  instrumentationKey: 'beaadd88-f251-4b2a-a09c-ea227a329aa8',
  googleApiKey: 'AIzaSyBfo9a3aDoFj_C9TyCowJa2g7Dq7pVpkSI',
  // ocpApimSubscriptionKey: '4be0f7ce94df456aafe7258b64d3a0ce',
  ocpApimSubscriptionKey: 'f3b3d532731f4286998eaf5cd0bb8b90',
  scApiSubscriptionKey: 'f3b3d532731f4286998eaf5cd0bb8b90',
  trediumUrl: 'https://epx-pharm-test.tredium.com/#/',
  apiVersionConfig: APIVersionConfig
};
